<template>
  <v-container>
    <v-row>
      <v-col class="mt-6 ml-4">
        <h1 class="display-1 font-weight-bold">申請一覧</h1>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="applicants"
          :loading="loading"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="xlsxImport()"
                class="mr-2"
              >
                <v-icon left>
                  mdi-tray-arrow-up
                </v-icon>
                xlsx登録
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <router-link
              :to="{ name: 'UsersByApplicant', params: { id: item.id } }"
              style="text-decoration: none;"
            >
              {{ item.id }}
            </router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <export-roles-dialog ref="roleexport"></export-roles-dialog>
  </v-container>
</template>

<script>
import axios from "axios"
import ExportRolesDialog from '@/components/ExportRolesDialog'

export default {
  components: {
    ExportRolesDialog,
  },
  data() {
    return {
      headers: [
        { text: '申請名', value: 'id'},
        { text: '代表者', value: 'name'},
        { text: '所属', value: 'belongs'},
      ],
      loading: false,
      applicants: [],
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      const accessToken = await this.$auth.getTokenSilently()
      await axios
        .get("/api/applicants", {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
        })
        .then(response => {
          this.applicants = response.data
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    xlsxImport() {
      this.$router.push({name: 'BulkImportUsersXlsx'})
    }
  }
}
</script>